<template>
	<div class="page flex-col">
		<div class="wrap1 flex-col" :style="{background: 'url('+img+')'}">
			<div class="section1 flex-col">
				<div class="ddtew">
					<p>账号登录</p>
				</div>
				<div class="mod2 flex-row ddde" style="margin-bottom: 23px;">
					<div class="bd1 flex-col">
						<el-input v-model="user_name" placeholder="请输入账户" style="height: 100%;"></el-input>
					</div>
				</div>
				<div class="mod3 flex-row ddde" style="margin-bottom: 23px;">
					<div class="box1 flex-col">
						<el-input v-model="password" type="password" placeholder="请输入密码"></el-input>
					</div>
				</div>
				<div style="color: #9A9DA4;padding-left: 37px;font-size: 14px;"><el-checkbox v-model="checked"></el-checkbox> 下次自动登录</div>
				<div class="mod4 flex-row">
					<button class="main1 flex-col" @click="login">
						<span class="info1">登录</span>
					</button>
				</div>
				
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "login",
		data() {
			return {
				user_name: '',
				password: '',
				sys_name: localStorage.getItem('sys_name') || '智慧捐赠',
				checked: true,
				img: require('../../assets/bjbj.png')
			}
		},
		methods: {
			login () {
				if (this.user_name == '' || this.password == '') {
					return
				}
				this.$http.request({
					url: '/api.admin.login/',
					method: 'post',
					data: {
						admin_name: this.user_name,
						password: this.password,
						is_corp: 1
					},
				}).then((res) => {
					if (res.code == 200) {
						localStorage.setItem(this.$_W.tokenHeaderName, res.data.token);
						localStorage.setItem('authority', JSON.stringify(res.data.authority))
						console.log(res.data.authority, res.data.authority.includes('1'), 'res.data.authority')
						if (!res.data.authority.includes('1')) {
							let a = [0, '/web/home/index', '/web/mechanism/list', '/web/node/list', '/web/device/list', '/web/trade/details-list', '/web/trade/details-list', '/web/trade/summary-list', '/web/system/config', '/web/system/config', '/system/maintain-mec', '/web/system/admins', '/web/system/role']
							this.$router.push(a[res.data.authority[0]])
						} else {
							this.$router.push({
								path: '/'
							})
						}
					} else {
						Vue.prototype.$message.error(res.message);
					}
				})
			}
		}
	}
</script>

<style scoped lang="less">
	.ddtew {
		padding-top: 33px;
		    padding-left: 37px;
		    margin-bottom: 30px;
		position: relative;
		&:after {
			    content: "";
			    position: absolute;
			    left: 37px;
			    width: 340px;
			    background-color: #f5f5f8;
			    height: 1px;
		}
		&:before {
			content: '';
			bottom: 0px;
			position: absolute;
			left: 37px;
			width: 90px;
			height: 3px;
			background: #3473FF;
		}
		p {
			font-size: 22px;
			font-weight: 600;
			color: #3473FF;
			line-height: 30px;
			padding-bottom: 24px;
		}
	}
	.ttiite {
		line-height: 140px;
		text-align: center;
		color:#3473FF !important;
		font-weight: 700;
		font-size: 24px;
	}
	
	.wrap1 {
	  z-index: 2;
	  height: 100vh;
	  
	  background-size: 1920 1080;
	  background-position: left top;
	  width: 100%;
	  justify-content: flex-end;
	  align-items: flex-end;
	  position: relative;
	
	}
	.wrap1:after {
		content: "";
		top: 248px;
		position: absolute;
		left: 362px;
		// background-image: url(../../assets/jkjd.png);
		width: 306px;
		height: 45px;
	}
	
	.img1 {
	  z-index: 13;
	  width: 774px;
	  height: 744px;
	      position: absolute;
	      right: 0px;
	      bottom: 0px;
	}
	
	.section1 {
	      z-index: 99;
	      height: 410px;
	      border-radius: 3px;
	      box-shadow: 0 2px 23px 0 rgba(51,116,255,13%);
	      overflow: hidden;
	      width: 410px;
	      position: absolute;
	      left: 1028px;
	      top: 230px;
	      background-color: #fff;
	}
	
	.mod1 {
	  z-index: auto;
	  width: 132px;
	  height: 92px;
	}
	
	.pic1 {
	  z-index: 45;
	  width: 132px;
	  height: 92px;
	}
	
	.mod2 {
	  z-index: auto;
	  width: 340px;
	  height: 57px;
	  margin: 0 auto;
	}
	
	.bd1 {
	  z-index: 37;
	  height: 57px;
	  border-radius: 3px;
	  width: 100%;
	  justify-content: center;
	  align-items: flex-start;
	}
	
	.txt1 {
	  z-index: 38;
	  width: 116px;
	  height: 28px;
	  display: block;
	  overflow-wrap: break-word;
	  color: rgba(153, 153, 153, 0.49);
	  font-size: 18px;
	  font-family: PingFangSC-Regular;
	  white-space: nowrap;
	  line-height: 28px;
	  text-align: left;
	}
	
	.mod3 {
	  z-index: auto;
	  width: 340px;
	  height: 57px;
	  margin: 0 auto;
	}
	
	.box1 {
	  z-index: 27;
	  height: 57px;
	  border-radius: 3px;
	  width: 100%;
	  justify-content: center;
	  align-items: flex-end;
	}
	
	.outer1 {
	  z-index: auto;
	  width: 385px;
	  height: 28px;
	  justify-content: space-between;
	}
	
	.word1 {
	  z-index: 34;
	  width: 100px;
	  height: 28px;
	  display: block;
	  overflow-wrap: break-word;
	  color: rgba(153, 153, 153, 0.49);
	  font-size: 18px;
	  font-family: PingFangSC-Regular;
	  white-space: nowrap;
	  line-height: 28px;
	  text-align: left;
	}
	
	.mod4 {
	  z-index: auto;
	      width: 340px;
	      height: 54px;
	      margin: 0 auto;
	}
	
	.main1 {
	  z-index: 40;
	      height: 45px;
	      border-radius: 5;
	      background: #3473ff!important;
	      color: #fff;
	      margin-top: 30px;
	      width: 340px;
	      outline: none;
	      align-items: center;
	      border: none;
	      text-align: center;
	      justify-content: center;
	      display: flex;
	      padding-right: 0;
	      cursor: pointer;
	}
	
	.info1 {
	      z-index: 41;
	      width: 43px;
	      height: 28px;
	      display: block;
	      overflow-wrap: break-word;
	      color: #fff;
	      font-size: 18px;
	      font-family: PingFangSC-Medium;
	      white-space: nowrap;
	}
	
	.mod5 {
	  z-index: auto;
	  width: 428px;
	  height: 25px;
	  margin: 14px 0 0 685px;
	}
	
	.outer2 {
	  z-index: 43;
	  width: 13px;
	  height: 14px;
	  border-radius: 2px;
	  border: 1px solid rgba(238, 238, 238, 1);
	  background-color: rgba(248, 248, 248, 1);
	  margin-top: 6px;
	}
	
	.word2 {
	  z-index: 42;
	  width: 145px;
	  height: 25px;
	  display: block;
	  overflow-wrap: break-word;
	  color: rgba(153, 153, 153, 1);
	  font-size: 18px;
	  font-family: PingFangSC-Regular;
	  white-space: nowrap;
	  line-height: 25px;
	  text-align: right;
	  margin-left: 7px;
	}
	
	.word3 {
	  z-index: 44;
	  width: 90px;
	  height: 25px;
	  display: block;
	  overflow-wrap: break-word;
	  color: rgba(108, 141, 255, 1);
	  font-size: 18px;
	  font-family: PingFangSC-Regular;
	  white-space: nowrap;
	  line-height: 25px;
	  text-align: left;
	  margin-left: 173px;
	}
	
	.mod6 {
	  z-index: auto;
	  width: 256px;
	  height: 45px;
	  margin: -393px 0 0 771px;
	}
	
	.word4 {
	  z-index: 24;
	  width: 256px;
	  height: 45px;
	  display: block;
	  overflow-wrap: break-word;
	  color: rgba(108, 141, 255, 1);
	  font-size: 32px;
	  font-family: PingFangSC-Medium;
	  white-space: nowrap;
	  line-height: 45px;
	  text-align: right;
	}
	
	.section3 {
	  z-index: auto;
	  width: 415px;
	  height: 71px;
	      position: absolute;
	      bottom: 40px;
	      left: 60px;
	}
	
	.info2 {
	  z-index: 21;
	  width: 400px;
	  height: 36px;
	  display: block;
	  overflow-wrap: break-word;
	  color: rgba(76, 132, 255, 1);
	  font-size: 25px;
	  font-family: PingFangSC-Semibold;
	  white-space: nowrap;
	  line-height: 36px;
	  text-align: right;
	  align-self: center;
	}
	
	.info3 {
	  z-index: 22;
	  width: 415px;
	  height: 29px;
	  display: block;
	  overflow-wrap: break-word;
	  color: rgba(76, 132, 255, 1);
	  font-size: 21px;
	  font-family: PingFangSC-Regular;
	  white-space: nowrap;
	  line-height: 29px;
	  text-align: right;
	  margin-top: 6px;
	}
	
	.pic2 {
	  z-index: 20;
	  position: absolute;
	  left: 0;
	  top: 0;
	  width: 600px;
	  height: 482px;
	}
	
	.mod8 {
	  z-index: 17;
	  position: absolute;
	  left: 599px;
	  top: 0;
	  width: 600px;
	  height: 619px;
	  background-color: rgba(255, 255, 255, 0.85);
	}

</style>
